@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  background-color: #eee;
  color: #434344;
  font-family: sans-serif;
  font-weight: 400;
}

/* Page Layout */

.container {
  padding-top: 6%;
  max-width: 80%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: space-between;
}

/* Header */

/* Main Section */

.wrapper {
  display: flex;
}

.main {
  flex-basis: 70%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 20px;
  padding: 10px;
}

.main h2 {
  font-size: 32px;
  margin: 1rem 0;
  color: #50c6db;
}

.main p {
  line-height: 1.8;
  font-size: 18px;
  word-wrap: break-word;
}

/* Sidebar */

.sidebar {
  padding: 20px;
  flex-basis: 30%;
  margin-right: 60px;
  border-top: 3px solid #693f7e;
  background-color: #f9f9f9;
  line-height: 1.2;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.sidebar ul {
  list-style-type: none;
  margin-top: 1rem;
  font-size: 0.75rem;
}

.sidebar ul > li {
  padding: 0.25rem 0;
  font-size: 1rem;
}

.logo {
  font-size: 2rem;
  margin-right: 1.5rem;
  color: #50c6db;
}

.email {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

/* Footer */

footer {
  color: #fff;
  text-align: center;
  padding: 10px 0;
  margin-top: 60px;
  background-color: #9f9c9c;
}

footer p {
  color: #434344;
  font-size: 12px;
  padding: 10px;
}

@media (max-width: 600px) {
  .wrapper {
    flex-direction: column;
  }

  .main {
    margin-right: 0;
    margin-bottom: 60px;
  }

  .container {
    padding-top: 15%;
    max-width: 100%;
  }
}

/* Highlight */

.highlight {
  margin-top: 20px;
  margin-bottom: 50px;
}

.highligtedText {
  border-radius: 5px;
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  background-color: #282a36;
  color: #f8f8f2;
  display: block;
  line-height: 1.8;
  font-size: 18px;
  word-wrap: break-word;
}

.media {
  height: 100;
  width: 10;
  background: "#065578";
  padding: "10px";
}

.card {
  margin-bottom: 1.5rem !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 1rem;
  text-align: center;
}

.cardTitle {
  margin-bottom: 1rem;
  padding-top: 20px;
  font-size: 2rem;
  line-height: 1.2;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
}

